import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col, Table } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"


const IndexPage = () => {
    const root = "/img/download/awards"

    return (
        <Layout pageTitle="Virtual Awards - Templates">
            <Row>
                <Col>
                    <Link to="/awards/small-business-awards">Back</Link>
                </Col>
            </Row>
            
            <Row className="mt-5">
                <Col md={8}>

                <h1>Nominee Templates</h1>

                    
                    <p className="para">
                    Transparent background templates you can insert your image into to post onto your social channels. 
                    Shout about your achievements, raise your profile and promote yourself as an award nominee or award finalist!
                     </p>
                </Col>
            </Row>

            <ImageDownload
                root={root}
                name="Award Nominee Template"
                filename="awardsimage"
                text="Use this template to announce your nomination in the Women's Business Awards"
                text2="You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAFKVUM7OW0/U0ju9n95c1V10f7x-RhJCw/view?utm_content=DAFKVUM7OW0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFKU-WYqYw/Hz0TwWPKIP8SqV29VRRq1g/view?utm_content=DAFKU-WYqYw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/142VBMQ97ctRchi_se6GrasXenfB0mY19jbY3PmUaByI/edit?usp=sharing"
            />

            <ImageDownload
                root={root}
                name="Award Finalist Template"
                filename="awardsphoto"
                text="Use this template to announce yourself as a finalist in the Women's Business Awards"
                text2="You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAFKVUM7OW0/U0ju9n95c1V10f7x-RhJCw/view?utm_content=DAFKVUM7OW0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFKU-WYqYw/Hz0TwWPKIP8SqV29VRRq1g/view?utm_content=DAFKU-WYqYw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/142VBMQ97ctRchi_se6GrasXenfB0mY19jbY3PmUaByI/edit?usp=sharing"

            />
            <ImageDownload
                root={root}
                name="Award Winner Template"
                filename="winner"
                text="Use this template to announce yourself as a winner in the Women's Business Awards"
                text2="You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAFKVUM7OW0/U0ju9n95c1V10f7x-RhJCw/view?utm_content=DAFKVUM7OW0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFKU-WYqYw/Hz0TwWPKIP8SqV29VRRq1g/view?utm_content=DAFKU-WYqYw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/142VBMQ97ctRchi_se6GrasXenfB0mY19jbY3PmUaByI/edit?usp=sharing"

            />


            <Row className="mt-5">
                <Col md={8}>

                <h1>Sponsor Templates</h1>

                    
                    <p className="para">
                       Use these templates to create images specific to your sponsored award and your audience. 
                    </p>
                </Col>
            </Row>
            
            <Row className="mt-5">
                
                <Col md={5}>
                    <img
                        src={`/img/wbc-conference-html-email.png`}
                        className="img-fluid"
                        alt=""
                        style={{ maxHeight: "350px" }}
                    />
                </Col>
                <Col>
                    <h3>Email Template</h3>
                    <p className="para">Use this template to send an email announcement to your network about your award sponsorship.</p>
                    
                    <a
                        href="https://docs.google.com/document/d/1LbIyC0khH1OQy4Yprc1xuQFOmxldiwrANp8RwwtlveU/edit?usp=sharing"
                        className="btn btn-primary m-3"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        View Template
                    </a>
                </Col>
            </Row>

            <ImageDownload
                root={root}
                name="Sponsor Images"
                filename="award-sponsor"
                text="Use this template to anounce your sponsorship in the Women's Business Awards"
                text2="You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAEu28_Hh-Q/6aFuwwwc-ZODyoJPL8ah1Q/view?utm_content=DAEu28_Hh-Q&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref=""
                copyHref="https://docs.google.com/document/d/1q3yNuyC7yzPsb-JUxVivlBh1_RjowEf04n57CTSBseo/edit?usp=sharing"
            />

<Row className="mt-7">
                <Col md={8}>

                <h1>General Templates</h1>

                    
                    <p className="para">
                       Use these templates to create images specific to your award and your audience. 
                    </p>
                </Col>
            </Row>

            <ImageDownload
                root={root}
                name="Award Countdown"
                filename="sb-award-countdown-square"
                text="Use this template to count down to the Women's Business Awards. Don't forget to use your unique URL if you have one! "
                text2="&quot; There are only 10 days left to go until the Women's Business Awards (location)! &quot; Adapt to suit the template and ready-to-go marketing copy below!"
                squareHref="https://www.canva.com/design/DAFefGmHSbk/wH2w-tQfy-TwOOY_bw97-w/view?utm_content=DAFefGmHSbk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFefBqcLmI/YXf__18eP2tQMouuOr-Y-Q/view?utm_content=DAFefBqcLmI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/10puqRIqzuL1TfqG1yAitHJGZhCpBrmJrJVIRgRuz6AQ/edit?usp=sharing"

            />
            
        </Layout>
    )
}


export default IndexPage
